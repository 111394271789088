// Generated by Framer (0b5b7f5)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/p8dptk4UIND8hbFWz9V7/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);

const cycleOrder = ["w6h9Lkcth", "tFb7RdYA9"];

const serializationHash = "framer-VJ3ZH"

const variantClassNames = {tFb7RdYA9: "framer-v-e79cz1", w6h9Lkcth: "framer-v-p8zmpe"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {duration: 0, type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Active: "tFb7RdYA9", Inactive: "w6h9Lkcth"}

const getProps = ({activeBG, activeIcon, height, iconChange, id, width, ...props}) => { return {...props, BEEYr18Ow: activeIcon ?? props.BEEYr18Ow ?? "var(--token-8766da81-4b16-4dc5-9abd-8dbd5bdab57d, rgb(26, 26, 26))", PmFSYIngT: iconChange ?? props.PmFSYIngT ?? "Framer", qn8z_xbAt: activeBG ?? props.qn8z_xbAt ?? "var(--token-e0c554e9-50e7-471e-b6bb-5654e9ea519a, rgb(177, 252, 3))", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "w6h9Lkcth"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;iconChange?: string;activeBG?: string;activeIcon?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, PmFSYIngT, qn8z_xbAt, BEEYr18Ow, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "w6h9Lkcth", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-p8zmpe", className, classNames)} data-border data-framer-name={"Inactive"} layoutDependency={layoutDependency} layoutId={"w6h9Lkcth"} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-98ea2f69-4c41-4e53-9c8c-7a98946cba16, rgb(230, 230, 230))", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "var(--token-09f22285-1280-482e-8b22-1cef593bf6a7, rgb(255, 255, 255))", borderBottomLeftRadius: 999, borderBottomRightRadius: 999, borderTopLeftRadius: 999, borderTopRightRadius: 999, ...style}} variants={{tFb7RdYA9: {"--border-bottom-width": "0px", "--border-left-width": "0px", "--border-right-width": "0px", "--border-top-width": "0px", backgroundColor: qn8z_xbAt}}} {...addPropertyOverrides({tFb7RdYA9: {"data-framer-name": "Active"}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-tsoirf-container"} layoutDependency={layoutDependency} layoutId={"XTgcIWNND-container"}><Phosphor color={"var(--token-8766da81-4b16-4dc5-9abd-8dbd5bdab57d, rgb(26, 26, 26))"} height={"100%"} iconSearch={PmFSYIngT} iconSelection={"FramerLogo"} id={"XTgcIWNND"} layoutId={"XTgcIWNND"} mirrored={false} selectByList={false} style={{height: "100%", width: "100%"}} weight={"fill"} width={"100%"} {...addPropertyOverrides({tFb7RdYA9: {color: BEEYr18Ow}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-VJ3ZH.framer-avrw2n, .framer-VJ3ZH .framer-avrw2n { display: block; }", ".framer-VJ3ZH.framer-p8zmpe { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: hidden; padding: 10px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-VJ3ZH .framer-tsoirf-container { flex: none; height: 20px; position: relative; width: 20px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-VJ3ZH.framer-p8zmpe { gap: 0px; } .framer-VJ3ZH.framer-p8zmpe > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-VJ3ZH.framer-p8zmpe > :first-child { margin-left: 0px; } .framer-VJ3ZH.framer-p8zmpe > :last-child { margin-right: 0px; } }", ".framer-VJ3ZH[data-border=\"true\"]::after, .framer-VJ3ZH [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 40
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"tFb7RdYA9":{"layout":["auto","auto"]}}}
 * @framerVariables {"PmFSYIngT":"iconChange","qn8z_xbAt":"activeBG","BEEYr18Ow":"activeIcon"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerjX2ag2FCF: React.ComponentType<Props> = withCSS(Component, css, "framer-VJ3ZH") as typeof Component;
export default FramerjX2ag2FCF;

FramerjX2ag2FCF.displayName = "Elements/Step-icon";

FramerjX2ag2FCF.defaultProps = {height: 40, width: 40};

addPropertyControls(FramerjX2ag2FCF, {variant: {options: ["w6h9Lkcth", "tFb7RdYA9"], optionTitles: ["Inactive", "Active"], title: "Variant", type: ControlType.Enum}, PmFSYIngT: {defaultValue: "Framer", placeholder: "Menu, Wifi, Box…", title: "Icon change", type: ControlType.String}, qn8z_xbAt: {defaultValue: "var(--token-e0c554e9-50e7-471e-b6bb-5654e9ea519a, rgb(177, 252, 3)) /* {\"name\":\"Primary-4\"} */", title: "Active BG", type: ControlType.Color}, BEEYr18Ow: {defaultValue: "var(--token-8766da81-4b16-4dc5-9abd-8dbd5bdab57d, rgb(26, 26, 26)) /* {\"name\":\"Gray-1\"} */", title: "Active icon", type: ControlType.Color}} as any)

addFonts(FramerjX2ag2FCF, [{explicitInter: true, fonts: []}, ...PhosphorFonts], {supportsExplicitInterCodegen: true})